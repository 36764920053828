.msit1{
    border: 1px solid lightgray;
     /* height: 6rem; */
    width: 99.5%;
    border-radius: 7px;
    display: flex;
    justify-content: space-between;
    padding: 4px 7px;

}

.list1 ul {
    list-style-type: none; /* Removes the bullet points */
    padding-left: 0; /* Removes default left padding */
    margin: 0; /* Removes any default margins */
    font-size: 14px;
}

.msit2{
    width:99.6%;
    /* height: 5rem; */
    border: 1px solid lightgray;
    border-radius: 8px;
    padding: 7px 7px;
    display: flex;
    justify-content:space-evenly;
}


.third_log{
    border-top: 1px solid lightgray;
}

 .third_log>ul>li{
    /* width: 60px; */
    padding: 5px;
    display: inline-block;
    list-style-type: none;
}

.third_log>ul>li a button{
    padding: 5px;
    text-decoration: none;
    background-color: #fff;
    border: 2px solid transparent;
}

 .third_log>ul>li a.active button {
    /* Optional: to give active button a green background */
    
    border: 2px solid darkgreen;
    border-bottom-color: #fff;
}

.third_log>ul>li  a:hover button {
    border: 2px solid darkgreen;
    border-bottom-color: #fff; /* Optional: to give button a green background on hover */
   
}

.table>thead>tr>th{
    font-size: 13px;
    font-weight: bold;
}

.table>tbody>tr>td{
    font-size: 12px;
    font-weight: 200;
}

.graph1{
    border-radius: 8px;
    border: 1px solid lightgray;
    width: 49.3%;
    height: auto;
    padding: 5px;
}